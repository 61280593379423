import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../../components/layout";
import SearchEngineOptimization from "../../components/SearchEngineOptimization";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import "./styles.scss";

function CapabilitiesArticlePage({ data: { capabilityArticle } }) {
	const { title, slug, content, capability, topic } = capabilityArticle;

	return (
		<>
			<Layout>
				<SearchEngineOptimization title={title} />
				<section className="section">
					<div className="container">
						<div className="capabilities-article-page">
							<Link
								to={`/${capability.slug}`}
								className="capabilities-article-page__return-link"
							>
								<div>
									<HiOutlineArrowNarrowLeft aria-hidden="true" size={24} />
								</div>
								<div>Return to {capability.title}</div>
							</Link>
							<div className="capabilities-article-page__hero">
								<div className="capabilities-article-page__subtitle">
									{topic.heading}
								</div>
								<h1 className="capabilities-article-page__title">{title}</h1>
							</div>
						</div>
						<div className="container container--is-narrow">
							{/* Markdown article content */}
							{content && (
								<div
									className="capabilities-article-page__body markdown"
									dangerouslySetInnerHTML={{
										__html: content.childMarkdownRemark.html,
									}}
								/>
							)}

							{/* More reads */}
							{topic.capability_article && (
								<div className="capabilities-article-page__more-topics">
									<div className="capabilities-article-page__more-topics-heading">
										More in {topic.heading}
									</div>
									<ul className="capabilities-article-page__more-topics-list">
										{topic.capability_article.map((article, index) => {
											if (article.slug === slug) {
												return null;
											}

											return (
												<li key={index}>
													<Link to={`/${article.slug}`}>{article.title}</Link>
												</li>
											);
										})}
									</ul>
								</div>
							)}
						</div>
					</div>
				</section>
			</Layout>
		</>
	);
}

export const query = graphql`
	query($slug: String!) {
		capabilityArticle: contentfulCapabilityArticle(slug: { eq: $slug }) {
			title
			slug
			content {
				childMarkdownRemark {
					html
				}
			}
			capability {
				slug
				title
			}
			topic {
				heading
				capability_article {
					title
					slug
				}
			}
		}
	}
`;

export default CapabilitiesArticlePage;
